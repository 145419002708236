<template>
  <div>
    <PageHeader />
    <b-row>
      <b-col cols="12" md="5">
        <b-row>
          <b-col v-if="userInfo.roleName === 'OWNER'">
            <label>Master</label>
            <b-select v-model="selectedMasterId">
              <b-select-option :value="null" disabled>
                กรุณาเลือก Master
              </b-select-option>
              <b-select-option value="">เลือกทุก Master</b-select-option>
              <b-select-option
                v-for="item in masters"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</b-select-option
              >
            </b-select>
          </b-col>
          <b-col v-if="userInfo.roleName !== 'AGENT'">
            <label>Agent</label>
            <b-select v-model="selectedAgentId">
              <b-select-option :value="null" disabled>
                กรุณาเลือก Agent
              </b-select-option>
              <b-select-option value="">เลือกทุก Agent</b-select-option>
              <b-select-option
                v-for="item in agents"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</b-select-option
              >
            </b-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
         <label>ตั้งแต่เวลา</label>
        <b-form-group >
          <DateTimePicker
              v-model="startDateTime"
              :max-date="maxStartDateTime"
              :min-date="minStartDateTime"
          />
        </b-form-group>
      </b-col>
      <b-col>
         <label>ถึงเวลา</label>
        <b-form-group>
          <DateTimePicker
              v-model="endDateTime"
              :max-date="maxEndDateTime"
              :min-date="minEndDateTime"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <label>.</label>
        <b-button block type="submit" variant="primary" @click="getGamePlaying">ดึงข้อมูล</b-button>
      </b-col>
    </b-row>

    <b-overlay :show="isLoading">
      <b-row>
        <b-col cols="6">
          <b-card>
            <div class="media border-bottom pb-1">
              <div class="media-body">
                <span class="text-muted">จำนวนผู้เล่น</span>
                <h2 class="mt-3 mb-1">{{ result.total_user.toLocaleString() }}
                  <small class="font-size-12 font-weight-normal">
                    คน
                  </small>
                </h2>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col cols="6">
          <b-card>
            <div class="media border-bottom pb-1">
              <div class="media-body">
                <span class="text-muted">ยอดแทง</span>
                <h2 class="mt-3 mb-1">{{ result.total_bet.toLocaleString() }}
                  <small class="font-size-12 font-weight-normal">
                    บาท
                  </small>
                </h2>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import GameService from '../../../services/game'
import dayjs from 'dayjs';

export default {
  page: {
    title: 'จำนวนยูสที่กำลังเล่น',
  },
  data() {
    return {
      selectedGameType: 1,
      filterGame: 1,
      startDateTime: dayjs().subtract(5, 'minute').format('YYYY-MM-DD HH:mm'),
      endDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      isLoading: false,
      selectedMasterId: null,
      selectedAgentId: null,
      
      result: {
        total_bet: 0,
        total_user: 0,
      }
    }
  },
  computed: {
    ...mapGetters([
      'miniGames',
      'providerGames',
      'lottoGames',
      'isMaster',
      'isOwner',
    ]),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    isOwnerOrMaster() {
      return this.isOwner || this.isMaster
    },
    masters() {
      return this.userInfo.masters || []
    },
    agents() {
      if (this.userInfo.roleName === 'OWNER') {
        return (
          this.userInfo.masters.find(
            (master) => master.id === this.selectedMasterId
          )?.children || []
        )
      } else if (this.userInfo.roleName === 'MASTER') {
        return this.userInfo.masters[0].children || []
      } else if (this.userInfo.roleName === 'AGENT') {
        return this.userInfo.masters[0].children || []
      }
      return []
    },
    minStartDateTime() {
      return dayjs(this.startDateTime).subtract(7, 'day').format('YYYY-MM-DD HH:mm')
    },
    maxStartDateTime() {
      return dayjs(this.endDateTime).subtract(1, 'minute').format('YYYY-MM-DD HH:mm')
    },
    minEndDateTime() {
      return dayjs(this.startDateTime).add(1, 'minute').format('YYYY-MM-DD HH:mm')
    },
    maxEndDateTime() {
      return dayjs(this.startDateTime).add(2, 'hour').format('YYYY-MM-DD HH:mm')
    }
  },
  watch: {
    agents(val) {
      if (val.length === 1) {
        this.selectedAgentId = this.userInfo.masters[0].children[0].id
      }
    },
  },
  methods: {
    ...mapActions(['fetchProviderGames']),
    async getGamePlaying(){
      this.isLoading = true
      try{
        let r = await GameService.fetchGamePlaying(this.selectedAgentId, this.startDateTime, this.endDateTime)
        this.result = {...r}
        this.isLoading = false
      }catch(e){
        this.isLoading = false
      }
    }
  },
}
</script>
